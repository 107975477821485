var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { namespace } from "vuex-class";
import { CaseFollowService } from "~/services/domain-service/case-follow.service";
import { CaseFollowRecordService } from "~/services/business-service/case-follow-record.service";
import { CustConfigService } from "~/services/management-service/cust-config.service";
import SvgIcon from "~/components/common/svg-icon.vue";
import DataBox from "~/components/common/data-box.vue";
var collectionManageModule = namespace("collection-manage");
var FollowRecordSearch = /** @class */ (function (_super) {
    __extends(FollowRecordSearch, _super);
    function FollowRecordSearch() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // get principalId() {
        //   return (this.currentCase.principal || {}).id;
        // }
        _this.searchModel = {
            caseId: "",
            findRecord: "",
            targetName: "",
            findWay: "",
            isEffective: "",
            target: "",
            isUpdate: 'NO',
            personalId: '',
            principalId: '',
            principalName: '',
            caseType: ""
        };
        _this.dataSet = [
            {
                level: "提示",
                sensitiveWord: ""
            },
            {
                level: "严重",
                sensitiveWord: ""
            }
        ];
        _this.dialog = {
            sensitiveWord: false
        };
        _this.rules = {
            targetName: [{ required: true, message: "请输入对象姓名", trigger: "blur" }],
            findWay: [
                { required: true, message: "请选择查找方式", trigger: "change" }
            ],
            isEffective: [{ required: true, message: "请选择是否有效", trigger: "change" }],
        };
        _this.relations = [];
        _this.loading = {
            state: false
        };
        return _this;
    }
    FollowRecordSearch.prototype.mounted = function () {
    };
    FollowRecordSearch.prototype.refresh = function () {
        this.reset();
    };
    FollowRecordSearch.prototype.saveRecord = function () {
        var _this = this;
        var form = this.$refs["form"];
        form.validate(function (valid) {
            if (!valid)
                return;
            var params = {
                principalId: _this.principalId,
                str: Object.values(_this.searchModel).map(function (v) { return v; }).join("&")
            };
            _this.loading.state = true;
            _this.caseFollowRecordService
                .checkString(params, _this.loading)
                .subscribe(function (data) {
                if (data) {
                    if (data.prompt.length || data.serious.length) {
                        _this.dialog.sensitiveWord = true;
                        var a = ""; // 敏感词提示
                        var b = ""; // 敏感词严重
                        if (data.prompt.length > 0) {
                            for (var _i = 0, _a = data.prompt; _i < _a.length; _i++) {
                                var i = _a[_i];
                                a += i;
                            }
                        }
                        if (data.serious.length > 0) {
                            for (var _b = 0, _c = data.serious; _b < _c.length; _b++) {
                                var i = _c[_b];
                                b += i;
                            }
                        }
                        _this.dataSet[0].sensitiveWord = a;
                        _this.dataSet[1].sensitiveWord = b;
                    }
                    else {
                        _this.conserve();
                    }
                }
                else {
                    _this.conserve();
                }
            });
        });
    };
    FollowRecordSearch.prototype.conserve = function () {
        var _this = this;
        var form = this.$refs["form"];
        if (form) {
            form.validate(function (valid) {
                if (!valid) {
                    return;
                }
                _this.searchModel.caseId = _this.caseId;
                // this.searchModel.targetName = this.currentCase.targetName
                // this.searchModel.findRecord = this.currentCase.findRecord
                // this.searchModel.findWay = this.currentCase.findWay
                // this.searchModel.isEffective = this.currentCase.isEffective
                _this.searchModel.personalId = _this.currentCase.personalId;
                _this.searchModel.principalId = _this.currentCase.principalId;
                _this.searchModel.principalName = _this.currentCase.principalName;
                _this.searchModel.certificateNo = _this.currentCase.certificateNo;
                _this.searchModel.caseType = _this.otherData.caseType || "TEL_CASE";
                _this.loading.state = true;
                _this.caseFollowRecordService.addFindRecord(_this.searchModel, _this.loading).subscribe(function (data) {
                    _this.$message.success("添加查找记录成功");
                    _this.dialog.sensitiveWord = false;
                    _this.reset();
                });
            });
        }
    };
    FollowRecordSearch.prototype.reset = function () {
        var form = this.$refs["form"];
        form.resetFields();
    };
    __decorate([
        Dependencies(CaseFollowService)
    ], FollowRecordSearch.prototype, "caseFollowService", void 0);
    __decorate([
        Dependencies(CustConfigService)
    ], FollowRecordSearch.prototype, "custConfigService", void 0);
    __decorate([
        Dependencies(CaseFollowRecordService)
    ], FollowRecordSearch.prototype, "caseFollowRecordService", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordSearch.prototype, "caseId", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordSearch.prototype, "currentCase", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordSearch.prototype, "principalId", void 0);
    __decorate([
        collectionManageModule.State
    ], FollowRecordSearch.prototype, "otherData", void 0);
    FollowRecordSearch = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon,
                DataBox: DataBox
            }
        })
    ], FollowRecordSearch);
    return FollowRecordSearch;
}(Vue));
export default FollowRecordSearch;
